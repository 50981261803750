import { setupAppInsights, track } from '$lib/helpers/appInsights.js';
import { setupNewRelic, track as newRelicTrack } from '$lib/helpers/newRelic';

setupNewRelic();
setupAppInsights();

export function handleError({ error, event, status, message }) {
	track.exception(error, { event, status, message });
	newRelicTrack.exception(error, { event, status, message });
	
	return error;
}
